import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import RegisterPage from '../pages/RegisterPage';
import CheckEmailPage from '../pages/CheckEmailPage';
import CheckPasswordPage from '../pages/CheckPasswordPage';
import Home from '../pages/Home';
import MessagePage from '../components/MessagePage';
import AuthLayouts from '../layout';
import Forgotpassword from '../pages/Forgotpassword';
import ProtectedRoute from '../components/ProtectedRoute';
import Inicio from '../pages/Inicio';  // Asegúrate que esté bien importado desde la carpeta correcta
import Team from '../components/Extras/team'; // La página del equipo


const router = createBrowserRouter([
    {
        path: "/",
        element: <AuthLayouts><Inicio /></AuthLayouts>, // Usa el componente Inicio correctamente
    },
    {
        path: "/app",
        element: <App />, 
        children: [
            {
                path: "register",
                element: <AuthLayouts><RegisterPage /></AuthLayouts>
            },
            {
                path: 'email',
                element: <AuthLayouts><CheckEmailPage /></AuthLayouts>
            },
            {
                path: 'password',
                element: <AuthLayouts><CheckPasswordPage /></AuthLayouts>
            },
            {
                path: 'forgot-password',
                element: <AuthLayouts><Forgotpassword /></AuthLayouts>
            },
            {
                path: "home",
                element: <ProtectedRoute><Home /></ProtectedRoute>,
                children: [
                    {
                        path: ':userId',
                        element: <MessagePage />
                    }
                ]
            }
        ]
    },
    {
        path: "/nosotros",  // Nueva ruta para el equipo
        element: <AuthLayouts><Team /></AuthLayouts> // Página de equipo (Team.js)
    }
]);

export default router;

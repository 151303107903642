import React from 'react';
import Avatar from './Avatar';
import { Link } from 'react-router-dom';

const UserSearchCard = ({ user, onClose }) => {
  const handleClick = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
<Link to={`/app/home/${user?._id}`} onClick={handleClick} className='flex flex-col items-center gap-3 p-4 border border-transparent hover:border-primary rounded cursor-pointer bg-gray-50 w-full'>
<div>
        <Avatar
          width={50}
          height={50}
          name={user?.name}
          userId={user?._id}
          imageUrl={user?.profile_pic}
        />
      </div>
      <div className='text-center w-full'>
        <div className='font-semibold text-ellipsis overflow-hidden w-full line-clamp-2'>
          {user?.name}
        </div>
        <p className='text-sm text-ellipsis overflow-hidden w-full line-clamp-2'>
          {user?.email}
        </p>
      </div>
    </Link>
  );
}

export default UserSearchCard;

import React, { Suspense } from "react";

const HeaderInicio = React.lazy(() =>
  import("../components/Inicio/HeaderInicio")
);
const HeroPage = React.lazy(() => import("../components/Inicio/HeroPage"));
const Stats = React.lazy(() => import("../components/Inicio/Stats"));
const Footer = React.lazy(() => import("../components/Inicio/Footer"));

const PsychologistList = React.lazy(() =>
  import("../components/Inicio/PsychologistList")
);
const ChatbotSection = React.lazy(() =>
  import("../components/Inicio/ChatbotSection")
);
const ThreeStepsSection = React.lazy(() =>
  import("../components/Inicio/ThreeStepsSection")
);
const ServicesSection = React.lazy(() =>
  import("../components/Inicio/ServicesSection")
);
const WorkWithUsSection = React.lazy(() =>
  import("../components/Inicio/WorkWithUsSection")
);
const LaunchSection = React.lazy(() =>
  import("../components/Inicio/LaunchSection")
);
const WhyUsSection = React.lazy(() =>
  import("../components/Inicio/WhyUsSection")
);
const Chatbot = React.lazy(() => import("../components/Extras/chatbot"));

const Inicio = () => {
  return (
    <div className="relative isolate bg-transparent">
      <HeaderInicio />
      <HeroPage />
      <Stats />

      <Suspense fallback={<div>Loading...</div>}>
        <ThreeStepsSection />
        <LaunchSection />
        <PsychologistList />
        <ServicesSection />
        <ChatbotSection />
        <WhyUsSection />
        <WorkWithUsSection />
        <Chatbot />
      </Suspense>

      <Footer />
    </div>
  );
};

export default Inicio;

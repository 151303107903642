import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  logout,
  setOnlineUser,
  setSocketConnection,
  setUser,
} from "../redux/userSlice";
import Sidebar from "../components/Sidebar";
import logo from "../assets/logotexto.png";
import io from "socket.io-client";
import UserSearchCard from "../components/UserSearchCard";
import toast from "react-hot-toast";
import MessagesModal from "../components/MessagesModal";
import Confetti from "react-confetti"; // Importamos el componente de confetti

const Home = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchUser, setSearchUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [messagesModalOpen, setMessagesModalOpen] = useState(false);
  const [showConfetti, setShowConfetti] = useState(true); // Estado para controlar el confetti

  // Función para ocultar el confetti después de 5 segundos
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 5000); // Confetti se muestra por 5 segundos

    return () => clearTimeout(timer); // Limpiamos el temporizador al desmontar el componente
  }, []);

  const fetchUserDetails = async () => {
    try {
      const URL = `${process.env.REACT_APP_BACKEND_URL}/api/user-details`;
      const token = localStorage.getItem("token");

      const response = await axios({
        url: URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`, // Asegúrate de que el token se envíe correctamente aquí
        },
        withCredentials: true,
      });

      if (response.data.data.logout) {
        dispatch(logout());
        navigate("/app/email");
      } else {
        dispatch(setUser(response.data.data));
      }
      console.log("current user Details", response);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSearchUser = async () => {
    const URL = `${process.env.REACT_APP_BACKEND_URL}/api/search-user`;
    try {
      setLoading(true);
      const response = await axios.post(URL, {
        search: search,
      });
      setLoading(false);

      const filteredUsers = response.data.data.filter(
        (user) =>
          user.name.startsWith("Practicante") ||
          user.name.startsWith("Psicólogo")
      );

      setSearchUser(filteredUsers);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  useEffect(() => {
    handleSearchUser();
  }, [search]);

  useEffect(() => {
    const socketConnection = io(process.env.REACT_APP_BACKEND_URL, {
      auth: {
        token: localStorage.getItem("token"),
      },
    });

    socketConnection.on("onlineUser", (data) => {
      console.log(data);
      dispatch(setOnlineUser(data));
    });

    dispatch(setSocketConnection(socketConnection));

    return () => {
      socketConnection.disconnect();
    };
  }, [dispatch]);

  const basePath = location.pathname === "/app/home";

  return (
    <div className="flex flex-col lg:flex-row h-screen overflow-hidden">
      {/* Mostrar confetti solo cuando el estado `showConfetti` es verdadero */}
      {showConfetti && (
        <Confetti width={window.innerWidth} height={window.innerHeight} />
      )}

      <div
        className={`fixed lg:static ${
          sidebarOpen ? "left-0" : "-left-64"
        } top-16 lg:top-0 lg:left-0 lg:w-24 transition-all duration-300 z-40 h-full lg:h-auto`}
      >
        <Sidebar
          onClose={() => setSidebarOpen(false)}
          onChatClick={() => setMessagesModalOpen(true)}
          closeSidebar={() => setSidebarOpen(false)}
        />
      </div>
      <div
        className={`flex-1 p-4 pt-20 lg:pt-4 transition-all duration-300 ${
          sidebarOpen ? "ml-24" : "ml-0"
        }`}
      >
        {basePath ? (
          <div className="text-center">
            <img
              src={logo}
              loading="lazy"
              alt="logo"
              className="mx-auto w-48"
            />
            <h1 className="text-2xl mt-4">
              Selecciona un psicólogo o un usuario
            </h1>
            <div className="mt-6 p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
              <h3 className="text-xl font-semibold">
                ¡Novedades próximamente!
              </h3>
              <p>
                Pronto se podrá realizar videollamadas, estamos trabajando en
                ello.
              </p>
            </div>
            <div className="mt-6 p-4 bg-white border rounded-lg shadow w-full max-w-lg mx-auto">
              <h3 className="text-xl font-semibold mb-4">Psicólogos</h3>
              {loading ? (
                <p>Cargando...</p>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {searchUser.map((user) => (
                    <UserSearchCard
                      key={user._id}
                      user={user}
                      closeSidebar={() => setSidebarOpen(false)}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        ) : (
          <Outlet />
        )}
      </div>
      <MessagesModal
        isOpen={messagesModalOpen}
        onClose={() => setMessagesModalOpen(false)}
        closeSidebar={() => setSidebarOpen(false)}
      />
    </div>
  );
};

export default Home;

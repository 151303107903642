import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Avatar from "./Avatar";
import { HiDotsVertical } from "react-icons/hi";
import { FaAngleLeft, FaPlus, FaImage, FaVideo } from "react-icons/fa6";
import uploadFile from "../helpers/uploadFile";
import { IoMdSend } from "react-icons/io";
import Loading from "./Loading";
import backgroundImage from "../assets/wallpaper.webp";
import dayjs from 'dayjs'; // Reemplazando Moment.js por Day.js

const MessagePage = () => {
  const params = useParams();
  const socketConnection = useSelector((state) => state?.user?.socketConnection);
  const user = useSelector((state) => state?.user);
  const [dataUser, setDataUser] = useState({
    name: "",
    email: "",
    profile_pic: "",
    online: false,
    _id: "",
  });
  const [openImageVideoUpload, setOpenImageVideoUpload] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    imageUrl: "",
    videoUrl: "",
  });
  const [loading, setLoading] = useState(false);
  const [allMessage, setAllMessage] = useState([]);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [allMessage]);

  const handleUploadImageVideoOpen = () => {
    setOpenImageVideoUpload((preve) => !preve);
  };

  const handleUploadImage = async (e) => {
    const file = e.target.files[0];

    setLoading(true);
    const uploadPhoto = await uploadFile(file);
    setLoading(false);
    setOpenImageVideoUpload(false);

    setMessage((preve) => ({
      ...preve,
      imageUrl: uploadPhoto.url,
    }));
  };

  const handleClearUploadImage = () => {
    setMessage((preve) => ({
      ...preve,
      imageUrl: "",
    }));
  };

  const handleUploadVideo = async (e) => {
    const file = e.target.files[0];

    setLoading(true);
    const uploadPhoto = await uploadFile(file);
    setLoading(false);
    setOpenImageVideoUpload(false);

    setMessage((preve) => ({
      ...preve,
      videoUrl: uploadPhoto.url,
    }));
  };

  const handleClearUploadVideo = () => {
    setMessage((preve) => ({
      ...preve,
      videoUrl: "",
    }));
  };

  useEffect(() => {
    if (socketConnection && params.userId) {
      socketConnection.emit("message-page", params.userId);

      socketConnection.emit("seen", params.userId);

      socketConnection.on("message-user", (data) => {
        setDataUser(data);
      });

      socketConnection.on("message", (data) => {
        setAllMessage(data);
      });
    }
  }, [socketConnection, params?.userId, user]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setMessage((preve) => ({
      ...preve,
      text: value,
    }));
  };

  const handleSendMessage = (e) => {
    e.preventDefault();

    if (message.text || message.imageUrl || message.videoUrl) {
      if (socketConnection) {
        socketConnection.emit("new message", {
          sender: user?._id,
          receiver: params.userId,
          text: message.text,
          imageUrl: message.imageUrl,
          videoUrl: message.videoUrl,
          msgByUserId: user?._id,
        });
        setMessage({
          text: "",
          imageUrl: "",
          videoUrl: "",
        });
      }
    }
  };

  return (
    <div
    style={{ 
      backgroundImage: `url(${backgroundImage})`, 
    }}
    className="min-h-screen flex flex-col h-screen"
  >
      <header className="sticky top-0 h-16 bg-white flex justify-between items-center px-4 z-10">
        <div className="flex items-center gap-4">
          <Link to={"/app/home"} className="lg:hidden">
            <FaAngleLeft size={25} />
          </Link>
          <div>
            <Avatar
              width={50}
              height={50}
              imageUrl={dataUser?.profile_pic}
              name={dataUser?.name}
              userId={dataUser?._id}
            />
          </div>
          <div>
            <h3 className="font-semibold text-lg my-0 text-ellipsis line-clamp-1">
              {dataUser?.name}
            </h3>
            <p className="-my-2 text-sm">
              {dataUser.online ? (
                <span className="text-primary">En línea</span>
              ) : (
                <span className="text-slate-400">Desconectado</span>
              )}
            </p>
          </div>
        </div>

        <div>
          <button
            className="cursor-pointer hover:text-primary"
            onClick={handleUploadImageVideoOpen}
          >
            <HiDotsVertical />
          </button>
        </div>
      </header>

      <section className="flex-1 overflow-y-auto bg-slate-200 bg-opacity-50 pt-4 pb-16">
        <div className="flex flex-col gap-2 mx-2">
          {allMessage.map((msg, index) => (
            <div
              key={msg._id || index}
              className={`message-container p-1 py-1 rounded w-fit max-w-[280px] md:max-w-sm lg:max-w-md ${
                user._id === msg?.msgByUserId ? "ml-auto bg-teal-100" : "bg-white"
              }`}
            >
              <div className="w-full relative">
                {msg?.imageUrl && (
                  <img
                    src={msg?.imageUrl}
                    loading="lazy"
                    className="w-full h-full object-scale-down"
                    alt="uploaded"
                  />
                )}
                {msg?.videoUrl && (
                  <video
                    src={msg.videoUrl}
                    loading="lazy"
                    className="w-full h-full object-scale-down"
                    controls
                  />
                )}
              </div>
              <p className="px-2">{msg.text}</p>
              <p className="text-xs ml-auto w-fit">
                {dayjs(msg.createdAt).format("hh:mm")} {/* Reemplazando Moment.js por Day.js */}
              </p>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
      </section>

      <section className="bg-white flex items-center px-4 sticky bottom-0 h-16 z-10">
        <div className="relative">
          <button
            onClick={handleUploadImageVideoOpen}
            className="flex justify-center items-center w-11 h-11 rounded-full hover:bg-primary hover:text-white"
          >
            <FaPlus size={20} />
          </button>

          {openImageVideoUpload && (
            <div className="bg-white shadow rounded absolute bottom-14 w-36 p-2">
              <form>
                <label
                  htmlFor="uploadImage"
                  className="flex items-center p-2 px-3 gap-3 hover:bg-slate-200 cursor-pointer"
                >
                  <div className="text-primary">
                    <FaImage size={18} />
                  </div>
                  <p>Imagen</p>
                </label>
                <label
                  htmlFor="uploadVideo"
                  className="flex items-center p-2 px-3 gap-3 hover:bg-slate-200 cursor-pointer"
                >
                  <div className="text-purple-500">
                    <FaVideo size={18} />
                  </div>
                  <p>Video</p>
                </label>

                <input
                  type="file"
                  id="uploadImage"
                  onChange={handleUploadImage}
                  className="hidden"
                />

                <input
                  type="file"
                  id="uploadVideo"
                  onChange={handleUploadVideo}
                  className="hidden"
                />
              </form>
            </div>
          )}
        </div>

        <form className="h-full w-full flex gap-2" onSubmit={handleSendMessage}>
          <input
            type="text"
            placeholder="Escribe aquí un mensaje"
            className="py-1 px-4 outline-none w-full h-full"
            value={message.text}
            onChange={handleOnChange}
          />
          <button className="text-primary hover:text-secondary">
            <IoMdSend size={28} />
          </button>
        </form>
      </section>
    </div>
  );


};

export default MessagePage;

import React from "react";

const PaginaEnConstruccion = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center text-center p-4">
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-6">
        {/* Ícono de construcción */}
        <div className="mb-6">
          <svg
            className="w-20 h-20 mx-auto text-yellow-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 11c0 .897-.392 1.706-1.012 2.26l-1.54 1.229a1.02 1.02 0 01-1.21.056c-.632-.402-1.41-.488-2.093-.23l-1.732.646a1.02 1.02 0 01-1.33-.787l-.265-1.582a2.992 2.992 0 00-.348-1.05 1.02 1.02 0 01.157-1.127l1.05-1.299a1.02 1.02 0 00.19-1.093L5.34 5.994a1.02 1.02 0 01.39-1.268l1.363-.948a2.992 2.992 0 011.607-.473l1.62.093a1.02 1.02 0 01.844.674l.388 1.55a2.992 2.992 0 00.69 1.177l1.305 1.258c.566.544 1.273.812 2.012.712l1.668-.223a1.02 1.02 0 011.187.663l.462 1.42a2.992 2.992 0 01-.723 2.934l-1.193 1.193a1.02 1.02 0 01-.769.294l-.815-.08a2.992 2.992 0 00-1.073.149l-1.74.58a1.02 1.02 0 01-.847-.136z"
            />
          </svg>
        </div>

        {/* Mensaje principal */}
        <h1 className="text-3xl font-extrabold text-gray-800 mb-4">
          Página en Construcción
        </h1>
        
        {/* Mensaje adicional */}
        <p className="text-gray-600 mb-8">
          Estamos trabajando para brindarte una mejor experiencia. ¡Vuelve pronto!
        </p>

        {/* Botón para volver al inicio */}
        <a
          href="/"
          className="inline-block bg-yellow-500 text-white font-semibold rounded-lg px-6 py-3 hover:bg-yellow-600 transition duration-200"
        >
          Volver al Inicio
        </a>
      </div>
    </div>
  );
};

export default PaginaEnConstruccion;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Avatar from './Avatar';
import { FaImage, FaVideo } from "react-icons/fa6";

const MessagesModal = ({ isOpen, onClose, closeSidebar }) => {
  const socketConnection = useSelector(state => state.user.socketConnection);
  const [allUser, setAllUser] = useState([]);

  useEffect(() => {
    if (socketConnection) {
      socketConnection.emit('sidebar');

      socketConnection.on('conversation', (data) => {
        const conversationUserData = data.map((conversationUser) => {
          if (conversationUser?.sender?._id === conversationUser?.receiver?._id) {
            return {
              ...conversationUser,
              userDetails: conversationUser?.sender,
            };
          } else if (conversationUser?.receiver?._id !== socketConnection.id) {
            return {
              ...conversationUser,
              userDetails: conversationUser.receiver,
            };
          } else {
            return {
              ...conversationUser,
              userDetails: conversationUser.sender,
            };
          }
        });

        setAllUser(conversationUserData);
      });

      return () => {
        socketConnection.off('conversation');
      };
    }
  }, [socketConnection]);

  if (!isOpen) return null;

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
      <div className='bg-white w-11/12 max-w-2xl p-4 rounded-lg shadow-lg'>
        <div className='flex justify-between items-center border-b pb-2'>
          <h2 className='text-xl font-bold'>Mensajes</h2>
          <button onClick={onClose} className='text-lg font-bold'>X</button>
        </div>
        <div className='overflow-y-auto max-h-96'>
          {allUser.length === 0 ? (
            <div className='flex flex-col items-center justify-center h-full text-center text-slate-400'>
              <p className='mt-4 text-lg'>No hay mensajes.</p>
            </div>
          ) : (
            allUser.map((conv) => (
              <NavLink
              to={`/app/home/${conv?.userDetails?._id}`}
              key={conv?._id}
              className='flex items-center gap-2 py-3 px-4 border-b border-slate-200 hover:bg-slate-100'
              onClick={() => {
                  onClose();
                  closeSidebar();
              }}
          >
          
                <Avatar
                  imageUrl={conv?.userDetails?.profile_pic}
                  name={conv?.userDetails?.name}
                  width={40}
                  height={40}
                />
                <div className='flex-1'>
                  <h3 className='text-sm font-semibold text-slate-800'>{conv?.userDetails?.name}</h3>
                  <div className='text-slate-500 text-xs flex items-center gap-1'>
                    {conv?.lastMsg?.imageUrl && (
                      <div className='flex items-center gap-1'>
                        <FaImage />
                        {!conv?.lastMsg?.text && <span>Image</span>}
                      </div>
                    )}
                    {conv?.lastMsg?.videoUrl && (
                      <div className='flex items-center gap-1'>
                        <FaVideo />
                        {!conv?.lastMsg?.text && <span>Video</span>}
                      </div>
                    )}
                    <p className='text-ellipsis overflow-hidden'>{conv?.lastMsg?.text}</p>
                  </div>
                </div>
                {Boolean(conv?.unseenMsg) && (
                  <p className='text-xs w-6 h-6 flex justify-center items-center ml-auto bg-primary text-white font-semibold rounded-full'>{conv?.unseenMsg}</p>
                )}
              </NavLink>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default MessagesModal;

import React, { useEffect, useState } from "react";
import { IoChatbubbleEllipses } from "react-icons/io5";
import { FaUserPlus } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import { BiLogOut, BiHome } from "react-icons/bi";
import Avatar from "./Avatar";
import { useDispatch, useSelector } from "react-redux";
import EditUserDetails from "./EditUserDetails";
import SearchUser from "./SearchUser";
import { logout } from "../redux/userSlice";

const Sidebar = ({ onClose, onChatClick, closeSidebar }) => {
  const user = useSelector((state) => state?.user);
  const [editUserOpen, setEditUserOpen] = useState(false);
  const [openSearchUser, setOpenSearchUser] = useState(false);
  const [allUser, setAllUser] = useState([]);
  const socketConnection = useSelector((state) => state?.user?.socketConnection);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (socketConnection && user._id) {
      socketConnection.emit("sidebar", user._id);

      socketConnection.on("conversation", (data) => {
        console.log("conversation", data);

        const conversationUserData = data.map((conversationUser) => {
          if (conversationUser?.sender?._id === conversationUser?.receiver?._id) {
            return {
              ...conversationUser,
              userDetails: conversationUser?.sender,
            };
          } else if (conversationUser?.receiver?._id !== user?._id) {
            return {
              ...conversationUser,
              userDetails: conversationUser.receiver,
            };
          } else {
            return {
              ...conversationUser,
              userDetails: conversationUser.sender,
            };
          }
        });

        setAllUser(conversationUserData);
      });
    }
  }, [socketConnection, user]);

  const handleLogout = () => {
    fetch('http://localhost:8080/api/logout', {  // Asegúrate de que la URL sea correcta
      method: 'GET', // Cambia de POST a GET
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          dispatch(logout());
          navigate('/');
          localStorage.clear();
        } else {
          console.error('Error al cerrar sesión en el servidor');
        }
      })
      .catch((error) => console.error('Error en el logout:', error));
  };
  

  return (
    <div className="flex flex-col h-full bg-white w-24 transition-all duration-300 lg:h-full lg:fixed">
  <div className="flex flex-col items-center justify-start py-5 space-y-4 flex-1">
    <NavLink
      to="/app/home"
      className={({ isActive }) =>
        `w-12 h-12 flex justify-center items-center cursor-pointer hover:bg-slate-200 rounded-full ${isActive && "bg-slate-200"}`
      }
      title="Home"
      onClick={closeSidebar}
    >
      <BiHome size={24} />
    </NavLink>
    <div
      title="Chat"
      onClick={onChatClick}
      className="w-12 h-12 flex justify-center items-center cursor-pointer hover:bg-slate-200 rounded-full"
    >
      <IoChatbubbleEllipses size={24} />
    </div>
    <div
      title="Add Friend"
      onClick={() => setOpenSearchUser(true)}
      className="w-12 h-12 flex justify-center items-center cursor-pointer hover:bg-slate-200 rounded-full"
    >
      <FaUserPlus size={24} />
    </div>
    <button
      className="mx-auto"
      title={user?.name}
      onClick={() => setEditUserOpen(true)}
    >
      <Avatar
        width={40}
        height={40}
        name={user?.name}
        imageUrl={user?.profile_pic}
        userId={user?._id}
      />
    </button>
  </div>
  <div className="flex flex-col items-center pb-5">
    <button
      title="Logout"
      className="w-12 h-12 flex justify-center items-center cursor-pointer hover:bg-slate-200 rounded-full"
      onClick={handleLogout}
    >
      <BiLogOut size={30} />
    </button>
    <button
      className="lg:hidden w-12 h-12 flex justify-center items-center cursor-pointer hover:bg-slate-200 rounded-full"
      onClick={onClose}
    >
      Cerrar
    </button>
  </div>
  {editUserOpen && (
    <EditUserDetails onClose={() => setEditUserOpen(false)} user={user} />
  )}
  {openSearchUser && (
    <SearchUser onClose={() => setOpenSearchUser(false)} closeSidebar={closeSidebar} />
  )}
</div>

  );
};

export default Sidebar;

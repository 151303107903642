import React, { useEffect, useState } from 'react';
import { IoSearchOutline } from "react-icons/io5";
import Loading from './Loading';
import UserSearchCard from './UserSearchCard';
import toast from 'react-hot-toast';
import axios from 'axios';
import { IoClose } from "react-icons/io5";

const SearchUser = ({ onClose, closeSidebar }) => {
  const [searchUser, setSearchUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const handleSearchUser = async () => {
    const URL = `${process.env.REACT_APP_BACKEND_URL}/api/search-user`;
    try {
      setLoading(true);
      const response = await axios.post(URL, {
        search: search
      });
      setLoading(false);

      // Filtrar usuarios que no comiencen con "Practicante" o "Psicólogo"
      const filteredUsers = response.data.data.filter(user =>
        !user.name.startsWith("Practicante") && !user.name.startsWith("Psicólogo")
      );

      setSearchUser(filteredUsers);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    handleSearchUser();
  }, [search]);

  console.log("searchUser", searchUser);
  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 bg-slate-700 bg-opacity-40 p-2 z-10'>
      <div className='w-full max-w-lg mx-auto mt-10'>
        {/**input search user */}
        <div className='bg-white rounded h-14 overflow-hidden flex '>
          <input 
            type='text'
            placeholder='Buscar un psicólogo o usuario'
            className='w-full outline-none py-1 h-full px-4'
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <div className='h-14 w-14 flex justify-center items-center'>
            <IoSearchOutline size={25}/>
          </div>
        </div>

        {/**display search user */}
        <div className='bg-white mt-2 w-full p-4 rounded'>
          {/**no user found */}
          {
            searchUser.length === 0 && !loading && (
              <p className='text-center text-slate-500'>No se encontraron usuarios.</p>
            )
          }

          {
            loading && (
              <p><Loading/></p>
            )
          }

          {
            searchUser.length !== 0 && !loading && (
              searchUser.map((user, index) => (
                <UserSearchCard key={user._id} user={user} onClose={() => { onClose(); closeSidebar(); }} />
              ))
            )
          }
        </div>
      </div>

      <div className='absolute top-0 right-0 text-2xl p-2 lg:text-4xl hover:text-white' onClick={onClose}>
        <button>
          <IoClose/>
        </button>
      </div>
    </div>
  );
};

export default SearchUser;

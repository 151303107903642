import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./HeaderInicio.css";
import logo from "../../assets/logotexto.png"; // o la versión WebP optimizada
import { HiMenu, HiX } from "react-icons/hi";

const HeaderInicio = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  // Cerrar el menú cuando se hace clic fuera de él
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="header-inicio">
      <div className="flex justify-between items-center">
        {/* Logo */}
        <img src={logo} alt="Logo" className="header-logo" />

        {/* Navegación en pantallas grandes */}
        <nav className="nav-links">
          <Link to="/" className="btn-secondary text-lg font-bold">
            Inicio
          </Link>
          <Link to="/nosotros" className="btn-secondary text-lg font-bold">
            Nosotros
          </Link>
          <Link to="/app/email" className="btn-secondary text-lg font-bold">
            Login
          </Link>
        </nav>

        {/* Botón de menú móvil */}
        <div className="hamburger-btn">
          <button onClick={() => setMenuOpen(!menuOpen)}>
            {menuOpen ? <HiX size={30} /> : <HiMenu size={30} />}
          </button>
        </div>
      </div>

      {/* Menú móvil */}
      {menuOpen && (
        <nav ref={menuRef} className="menu-mobile">
          <Link to="/" onClick={() => setMenuOpen(false)}>
            Inicio
          </Link>
          <Link to="/nosotros" onClick={() => setMenuOpen(false)}>
            Nosotros
          </Link>
          <Link to="/app/email" onClick={() => setMenuOpen(false)}>
            Login
          </Link>
        </nav>
      )}

      {/* Fondo degradado */}
      <div className="header-bg-gradient" />
    </header>
  );
};

export default HeaderInicio;


import { Outlet } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import React from 'react';

function App() {
  return (
    <>
      <Toaster />
      <main>
        <Outlet />
      </main>
    </>
  );
}

export default App;
